/**
 * @typedef {import('gatsby-plugin-image').IGatsbyImageData} ImageData
 */
import { getImage } from 'gatsby-plugin-image';

/**
 * @param {string} needle
 * @param {Readonly<{ node: Readonly<{ childImageSharp: Readonly<{ gatsbyImageData: ImageData }>, id: string, relativePath: string }> }>[]} haystack
 * @returns {ImageData | null}
 */
export function findImage(needle, haystack) {
	const image = haystack.find((image) => image.node.relativePath === needle);

	if (image?.node === undefined) {
		return null;
	}

	return getImage(image.node) ?? null;
}

const imageList = {
	agents: {
		alt: 'A real estate agent and two people in front of a house',
		path: 'hero-real-estate-agents.png',
	},
	franchise: {
		alt: 'Two homeowners and a mover standing in front of a truck holding boxes',
		path: 'hero-franchise.png',
	},
	home: {
		alt: 'A close up of a hand with an image of a check box and a house on a phone',
		path: 'home/hero-home.png',
	},
	homeowners: {
		alt: 'A person holding a tablet in front of a tall building gesturing towards it',
		path: 'hero-homeowners.png',
	},
	lenders: {
		alt: 'A lender discussing options with a home buyer',
		path: 'hero-lenders.png',
	},
};

export default imageList;
