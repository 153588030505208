/**
 * @typedef {import('gatsby-plugin-image').IGatsbyImageData} ImageData
 * @typedef {React.PropsWithChildren<{ readonly data: { readonly file: ImageData } }>} Props
 */

import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import content from 'content/content.json';
import Hero from 'components/shared/Hero';
import imageList from '../config/images';
import Layout from 'components/Layout';
import PropTypes from 'prop-types';
import React from 'react';
import seo from 'config/seo.json';

/** @extends {React.Component<Props>} */
export default class Lenders extends React.Component {
	/**
	 * @private
	 * @param {string} headline
	 * @returns {JSX.Element | null}
	 */
	renderHeader(headline) {
		if (!headline) {
			return null;
		}

		return <h4 className="font-semibold mb-4 text-primary">{headline}</h4>;
	}

	/**
	 * @private
	 * @param {string[]} list
	 * @returns {JSX.Element | null}
	 */
	renderList(list) {
		if (list.length === 0) {
			return null;
		}

		const itemList = list.map(
			/**
			 * @param {string} content
			 * @returns {JSX.Element}
			 */
			(content) => <li key={content}>{content}</li>
		);

		return <ul className="list-disc list-inside mb-4 ml-4">{itemList}</ul>;
	}

	/**
	 * @private
	 * @returns {JSX.Element[]}
	 */
	renderBody() {
		return content.pages.lenders.content.map(
			/**
			 * @param {{ content: string, list: string[], title: string }} section
			 * @returns {JSX.Element}
			 */
			(section) => (
				<React.Fragment key={section.content}>
					{this.renderHeader(section.title)}
					<p
						className="mb-4"
						dangerouslySetInnerHTML={{ __html: section.content }}
					/>
					{this.renderList(section.list)}
				</React.Fragment>
			)
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<Layout
				title={seo.lenders.title}
				description={seo.lenders.description}
			>
				<Hero
					alt={imageList.lenders.alt}
					buttonText={content.pages.lenders.hero.button.text}
					buttonUrl={content.pages.lenders.hero.button.link}
					content={content.pages.lenders.hero.content}
					image={getImage(this.props.data.file)}
					isExternalLink={true}
					title={content.pages.lenders.hero.title}
					paddingBottom="12"
				/>
				<section className="grid grid-cols-1 lg:grid-cols-3 gap-12 max-w-7xl mx-auto px-8 lg:px-16 py-12">
					<header className="">
						<h3 className="font-semibold mb-8 text-3xl">
							{content.pages.lenders.aside.title}
						</h3>
						<p>{content.pages.lenders.aside.content}</p>
					</header>
					<div className="lg:col-span-2">{this.renderBody()}</div>
				</section>
			</Layout>
		);
	}
}

Lenders.propTypes = {
	data: PropTypes.object,
};

export const pageQuery = graphql`
	{
		file(relativePath: { eq: "hero-lenders.png" }) {
			id
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
