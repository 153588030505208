/**
 * @typedef {import('gatsby-plugin-image').IGatsbyImageData} ImageData
 * @typedef {import('react').PropsWithChildren<Readonly<{
 * 	alt: string,
 * 	backgroundColor: string,
 * 	buttonText: string,
 * 	buttonUrl: string,
 * 	content: string,
 * 	image: ImageData,
 * 	isExternalLink: boolean,
 * 	paddingBottom: string,
 * 	title: string
 * }>>} Props
 */

import { GatsbyImage } from 'gatsby-plugin-image';
import Button from 'components/shared/Button';
import ExternalButton from 'components/shared/ExternalButton';
import PropTypes from 'prop-types';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class Hero extends React.Component {
	render() {
		return (
			<section
				className={`bg-${this.props.backgroundColor} bg-opacity-25`}
			>
				<div
					className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center max-w-7xl mx-auto px-8 lg:px-16 pb-12 lg:pb-${this.props.paddingBottom} pt-12`}
				>
					<header>
						<h1 className="font-semibold mb-8 text-7xl">
							{this.props.title}
						</h1>
						<p className="mb-8">{this.props.content}</p>
						{this.props.isExternalLink ? (
							<ExternalButton
								url={this.props.buttonUrl}
								text={this.props.buttonText}
							/>
						) : (
							<Button
								url={this.props.buttonUrl}
								text={this.props.buttonText}
							/>
						)}
					</header>
					<figure className="hidden lg:block">
						<GatsbyImage
							alt={this.props.alt}
							image={this.props.image}
						/>
					</figure>
				</div>
			</section>
		);
	}
}

Hero.defaultProps = {
	backgroundColor: 'secondary',
	isExternalLink: false,
	paddingBottom: '0',
};

Hero.propTypes = {
	alt: PropTypes.string,
	backgroundColor: PropTypes.string,
	buttonText: PropTypes.string,
	buttonUrl: PropTypes.string,
	content: PropTypes.string,
	image: PropTypes.object,
	imageKey: PropTypes.string,
	isExternalLink: PropTypes.bool,
	paddingBottom: PropTypes.string,
	title: PropTypes.string,
};
